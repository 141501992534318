import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChartLine } from 'react-icons/fa';

const TerminalCard = ({ terminal }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/graficos/${terminal.guid}`);
  };

  return (
    <div 
      className="bg-green-500 p-4 rounded-lg shadow-md cursor-pointer hover:bg-green-600 transition"
      onClick={handleClick}
    >
      <h2 className="text-xl font-bold mb-2">{terminal.guid}</h2>
      <p>Temp. Aire: {terminal.temperature_air} °C</p>
      <p>Humedad Aire: {terminal.humidity_air} %</p>
      <p>Temp. Suelo 30cm: {terminal.temperature_soil_30cm} °C</p>
      <p>Humedad Suelo 30cm: {terminal.humidity_soil_30cm} %</p>
      <p>Conductividad Suelo 30cm: {terminal.conductivity_soil_30cm}</p>
      <p>Temp. Suelo 60cm: {terminal.temperature_soil_60cm} °C</p>
      <p>Humedad Suelo 60cm: {terminal.humidity_soil_60cm} %</p>
      <p>Conductividad Suelo 60cm: {terminal.conductivity_soil_60cm}</p>

      <div className="mt-4">
        <button 
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center hover:bg-blue-600 transition"
        >
          <FaChartLine className="mr-2" /> Ver Gráficos
        </button>
      </div>
    </div>
  );
};

export default TerminalCard;