import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import { ChartContainer } from './ui/ChartContainer';

const GraphComponent = ({ guid, token }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!guid || !token) {
          console.error('GUID o token no están definidos');
          return;
        }

        const apiUrl = process.env.REACT_APP_API_URL;

        const response = await axios.get(`${apiUrl}/lecturas/getLecturasByGuid/${guid}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        const processedData = response.data.data.map(item => ({
          ...item,
          hora: item.hora.split('.')[0].slice(0, 5),
          temperature_air: parseFloat(item.temperature_air),
          humidity_air: parseFloat(item.humidity_air),
          temperature_soil_30cm: parseFloat(item.temperature_soil_30cm),
          humidity_soil_30cm: parseFloat(item.humidity_soil_30cm),
          conductivity_soil_30cm: parseFloat(item.conductivity_soil_30cm),
          temperature_soil_60cm: parseFloat(item.temperature_soil_60cm),
          humidity_soil_60cm: parseFloat(item.humidity_soil_60cm),
          conductivity_soil_60cm: parseFloat(item.conductivity_soil_60cm),
        }));

        setData(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [guid, token]);

  const renderChart = (dataKey, label, unit) => (
    <Card className="mb-4">
      <CardHeader>
        <CardTitle>{label}</CardTitle>
      </CardHeader>
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="hora" />
            <YAxis />
            <Tooltip formatter={(value) => `${value} ${unit}`} />
            <Line type="monotone" dataKey={dataKey} stroke="#8884d8" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );

  return (
    <ChartContainer>
      {renderChart("temperature_air", "Temperatura Ambiente", "C°")}
      {renderChart("humidity_air", "Humedad Ambiente", "%")}
      {renderChart("temperature_soil_30cm", "Temp. Suelo 30cm", "C°")}
      {renderChart("humidity_soil_30cm", "Humedad Suelo 30cm", "%")}
      {renderChart("conductivity_soil_30cm", "Conductividad Suelo 30cm", "")}
      {renderChart("temperature_soil_60cm", "Temp. Suelo 60cm", "C°")}
      {renderChart("humidity_soil_60cm", "Humedad Suelo 60cm", "%")}
      {renderChart("conductivity_soil_60cm", "Conductividad Suelo 60cm", "")}
    </ChartContainer>
  );
};

export default GraphComponent;