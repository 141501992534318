import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import axios from 'axios';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const MapaPage = ({ token }) => {
  const [terminales, setTerminales] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTerminales = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;

        if (!token) {
          console.log("Token no encontrado. Redirigiendo al login.");
          navigate('/login');
          return;
        }

        const response = await axios.get(`${apiUrl}/lecturas/terminales`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data.cod === 200 && Array.isArray(response.data.data)) {
          setTerminales(response.data.data);
        } else {
          console.error('Error en la respuesta:', response.data.message);
          setTerminales([]);
        }
      } catch (error) {
        console.error('Error llamada:', error.message);
        if (error.response && [400, 401, 403].includes(error.response.status)) {
          console.log("Token inválido o expirado, redirigiendo al login.");
          navigate('/login');
        }
      }
    };

    fetchTerminales();
  }, [token, navigate]);

  return (
    <MapContainer center={[-39.5, -72.5]} zoom={7} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        attribution='&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {terminales.map((terminal, index) => (
        <Marker key={index} position={[terminal.latitud, terminal.longitud]}>
          <Popup>
            <strong>{terminal.guid}</strong><br />
            <p>Temp. Aire: {terminal.temperature_air} °C</p>
            <p>Humedad Aire: {terminal.humidity_air} %</p>
            <p>Temp. Suelo 30cm: {terminal.temperature_soil_30cm} °C</p>
            <p>Humedad Suelo 30cm: {terminal.humidity_soil_30cm} %</p>
            <p>Conductividad Suelo 30cm: {terminal.conductivity_soil_30cm}</p>
            <p>Temp. Suelo 60cm: {terminal.temperature_soil_60cm} °C</p>
            <p>Humedad Suelo 60cm: {terminal.humidity_soil_60cm} %</p>
            <p>Conductividad Suelo 60cm: {terminal.conductivity_soil_60cm}</p>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapaPage;